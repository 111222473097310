/* global */
body {
    font-weight: 500;
}

.btn {
    margin-top: 30px !important;
    text-align: center !important;
    border-radius: 0 !important;
    border-color: black !important;
    border: 1px solid !important;
    background-color: black !important;
    color: white !important;
    height: 50px !important;
    width: 125px !important;
    letter-spacing: 1px !important;
    text-transform: none !important;
    font-size: 15px !important;
    transition: 0.3s !important;
}

.btn:hover {
    color: black !important;
    text-align: center !important;
    border-color: black !important;
    background-color: white !important;
}

.sub-title {
    padding: 70px;
    display: block;
    letter-spacing: 3px;
    text-align: center;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 800;
}

.text-sub-title {
    margin: 0;
    padding: 60px;
    padding-bottom: 30px;
    font-size: 24px;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-weight: 300;
}

.img-logo {
    height: 150px;
    width: 150px;
    float: left;
}

/* Header */
#header-container {
    position: relative;
    text-align: center;
    height: 200px;
}

#header-title {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    letter-spacing: 3px;
    font-size: 60px;
    font-weight: 500;
    color: #ffffff;
}

/* BackgroundVideo */
#bg-header-video {
    position: fixed;
    top: 0;
    width: 100%;
}
#bg-video-container {
    overflow: hidden;
    position: absolute;
    width: 100%;
    z-index: -1;
    height: 351.5px;
}

/* Footer */
#footer-container {
    padding: 40px;
    background-color: black;
    color: white;
    overflow: auto;
}

.footer-top-container {
    overflow: auto;
}

.footer-top-left-container {
    width: 50%;
    float: left;
}
.footer-top-right-container {
    width: 50%;
    float: left;
}
.footer-top-inner {
    width: 100px;
    float: right;
}

.footer-sub-title {
    text-align: center;
    font-weight: bold;
    letter-spacing: 0.5px;
    font-size: 14px;
}

.footer-title {
    font-size: 24px;
}

.footer-text {
    font-weight: 200;
    font-size: 14px;
}

.footer-links {
    font-weight: 200;
    letter-spacing: 0.5px;
    text-align: center;
    line-height: 26px;
}

.footer-links:hover {
    text-decoration: underline;
}

#footer-copyright-notice {
    font-weight: 300;
    font-size: 12px;
    text-align: center;
}

@media screen and (max-width: 600px) {
    #footer-container {
        padding: 20px;
        padding-right: 0px;
        padding-left: 40px;
    }
    .footer-top-inner {
        width: 100%;
    }
    .footer-links {
        line-height: 10px;
    }
}

/* ImageDescription */
.i-d-container {
    overflow: auto;
    background-color: #ffffff;
}

.i-d-sub-title {
    padding-left: 0px;
}

.i-d-sub-container {
    width: 50%;
}

.i-d-text {
    padding-left: 12%;
    padding-right: 12%;
    line-height: 40px;
}

.blur {
    display: block !important;
}
.i-d-image {
    display: block;
    width: 100%;
}

.i-d-sub-container-left {
    float: left;
}
.i-d-sub-container-right {
    float: right;
}

@media screen and (max-width: 1000px) {
    .i-d-text {
        font-size: 14px;
        line-height: 30px;
    }

    .i-d-sub-title {
        font-size: 20px;
        padding-bottom: 0px;
    }
}
@media screen and (max-width: 800px) {
    .i-d-sub-container {
        width: 100%;
    }
    .i-d-text {
        padding: 15%;
        padding-top: 20px;
        text-align: center;
    }
    .i-d-sub-title {
        padding-left: 0;
        padding-top: 20px;
        padding-bottom: 20px;
        padding-right: 0;
        text-align: center;
    }
}
/* ImageGallery */
.image-gallery-container {
    background-color: #ffffff;
    overflow: hidden;

    align-items: center;
    justify-content: center;
    width: 100%;
}
.image-gallery-image {
    display: flex;
    max-width: 100% !important;
    object-fit: cover;
}
.image-gallery-col {
    float: left;
    width: 32%;
    margin: 10px;
}
@media screen and (max-width: 1500px) {
    .image-gallery-col {
        width: 31.7%;
    }
}
@media screen and (max-width: 1300px) {
    .image-gallery-col {
        width: 32%;
    }
}
@media screen and (max-width: 1300px) {
    .image-gallery-col {
        width: 31.5%;
    }
}
@media screen and (max-width: 1100px) {
    .image-gallery-col {
        width: 48%;
    }
}
@media screen and (max-width: 1000px) {
    .image-gallery-col {
        width: 47.5%;
    }
}
@media screen and (max-width: 800px) {
    .image-gallery-col {
        width: 46.5%;
    }
}
@media screen and (max-width: 600px) {
    .image-gallery-col {
        float: none;
        width: 95%;
    }
}
/* DescHeader */
.desc-header-text {
    text-align: center;
    padding-left: 12%;
    padding-right: 12%;
    padding-bottom: 200px;
    line-height: 40px;
    margin-bottom: 0px;
}

.desc-header-title {
    text-align: center;
}

/* NavigationBar */

#nav-bar-container {
    padding-top: 40px;
    padding-bottom: 40px;
    padding-right: 40px;
    text-align: right;
}

.nav-ul {
    list-style-type: none;
    display: inline-block;
    overflow: hidden;
    margin: 0;
    padding: 0;
}

.nav-li {
    float: left;
    padding: 14px 16px;
}

.nav-li-button {
    float: left;
}

.nav-li a {
    color: black;
    display: block;
    text-transform: uppercase;
    text-align: center;
    text-decoration: none;
}

@media screen and (max-width: 900px) {
    .nav-li {
        float: none;
    }
    .nav-ul {
        display: block;
        margin: auto;
        padding-left: 40px;
    }
    .nav-li-button {
        float: none;
        text-align: center;
    }
}
/* 
    ---- ---- ---- ---- ---- Contact ---- ---- ---- ---- ----
*/
#contact-container {
    background-color: #fafafa;
}
#contact-form-container {
    padding: 50px;
}
/* 
    ---- ---- ---- ---- ---- Home ---- ---- ---- ---- ----
*/
#home-container {
    background-color: #fafafa;
}

/* Slideshow */

.slideshow {
    padding-bottom: 100px;
    margin: 0 auto;
    overflow: hidden;
    object-fit: cover;
    max-width: 500px;
}

.slideshowSlider {
    white-space: nowrap;
    transition: ease 750ms;
}

.slide {
    display: inline-block;

    height: auto;
    width: 100%;
    border-radius: 40px;
}

.slide img {
    height: auto;
    width: 100%;
}

.slideshowDots {
    text-align: center;
}

.slideshowDot {
    display: inline-block;
    height: 20px;
    width: 20px;
    border-radius: 50%;

    cursor: pointer;
    margin: 15px 7px 0px;

    background-color: #c4c4c4;
}

.slideshowDot.active {
    background-color: #6a0dad;
}

/* Title slideshow*/
#title-slideshow-container {
    background-color: #ffffff;
    margin: 0 auto;
    overflow: hidden;
}

.title-slideshow-height {
    height: 600px;
}

.title-slideshow-slider {
    white-space: nowrap;
    transition: ease 750ms;
}

.title-slideshow-slide {
    display: inline-block;

    width: 100%;
    border-radius: 40px;
}

.title-slideshow-slide img {
    object-fit: cover;
    width: 100%;
}

#title-slideshow-text {
    text-align: center;
    padding-left: 12%;
    padding-right: 12%;
    padding-bottom: 200px;
    line-height: 40px;
    margin-bottom: 0px;
}

/* About */
#about-container {
    background-color: #ffffff;
}
.about-image {
    width: 100%;
    object-fit: cover;
    height: 500px;
}

#about-text {
    text-align: center;
    padding-left: 12%;
    padding-right: 12%;
    padding-bottom: 200px;
    line-height: 40px;
    margin-bottom: 0px;
}

.about-sub-title {
    padding: 50px;
}

/* 
    ---- ---- ---- ---- ---- Portfolio ---- ---- ---- ---- ----
*/

#portfolio-container {
    background-color: #fafafa;
}
